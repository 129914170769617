import React, { useContext } from "react";
import { Modal, Table } from "react-bootstrap";
import Pagination_Holder from "../../Pagination_Holder/Pagination_Holder";
import "./WalletHitsoryModal.css";
import { Context } from "../../../../utils/context";

const WalletHistoryModal = (props) => {
  const { IMG_URL, userdata } = useContext(Context);

  console.log(userdata);

  return (
    <section className="WalletHistoryModal modal-holder">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="WalletHistoryModal modal-holder"
      >
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Request</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="withdraw_reuqestmodal"></div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default WalletHistoryModal;
