import React, { useContext, useState } from "react";
import "./UnitUpdateModal.css";
import { Form, InputGroup, Modal } from "react-bootstrap";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { CancelButton } from "../../Button";
import Successfull_Modal from "../../Successfull_Modal/Successfull_Modal";

const UnitUpdateModal = (props) => {
  const { postData, isTokenValid } = useContext(Context);
  const [walletError, setWalletError] = useState("");
  const [successModal, setSuccessModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await postData(`/stock-management/${props.id}`, data);
    if (res?.success) {
      setSuccessModal(true);
      await isTokenValid();
      props.getDataAll();
      setTimeout(() => {
        setSuccessModal(false);
        props.handleClose();
        reset();
      }, 3000);
    } else {
      setWalletError(res?.message);
      console.error("Error fetching data:", res?.error);
    }
  };

  return (
    <section className="WithdrawRequestModal modal-holder">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="WithdrawRequestModal modal-holder"
        onHide={props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Unit</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="withdraw_reuqestmodal">
            <div className="form_holder">
              <div className="col-md-6">
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Unit</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="unit"
                        placeholder="Unit"
                        className={classNames("", {
                          "is-invalid": errors?.unit,
                        })}
                        {...register("unit", {
                          required: "Unit is required",
                          validate: (value) => {
                            if (!Number.isInteger(Number(value))) {
                              return "Unit must be an integer.";
                            }
                            if (value < 1) {
                              return "Unit cannot be Zero Or negative.";
                            }
                            return true;
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.unit && (
                      <span className="text-danger">{errors.unit.message}</span>
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="btn_holder">
                <div className="cancel_btn_holder">
                  <CancelButton name="Cancel" handleClose={props.handleClose} />
                </div>

                <div className="save_btn_holder">
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Successfull_Modal
        show={successModal}
        onHide={() => setSuccessModal(false)}
        message={"Unit updated successfully"}
      />
    </section>
  );
};

export default UnitUpdateModal;
