import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";
import { AddButton, EditButton, DeletButton } from "../../common/Button";
import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";
import Pagination from "react-bootstrap/Pagination";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data,
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();
  const [searchType, setSearchType] = useState("");

  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const getDataAll = async () => {
    const response = await getData(
      `/masters/coupon-codes?page=${onPageChange}&per_page=${perPage}&term=${search}&searchType=${searchType}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    settotalPages(response?.data?.totalPages);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    search,
    searchType,
    onPageChange,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/masters/coupon-codes/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(
        `/masters/coupon-codes/${recordToDeleteId}`
      );
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Coupon Codes"} link={"/settings/Coupon-codes"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <div className="row MainRowsec">
                <section className="Tabels tab-radio tab-radio ">
                  <div className="">
                    {/* container */}
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="add me-3">
                          {isAllow?.includes(62) ? (
                            <Link
                              // to="/employee/employee_details/add"
                              type="button"
                              className="btn btn-add pe-3"
                            >
                              <div onClick={() => handleShow()}>
                                <img
                                  src={plus}
                                  className="plus me-2 ms-0"
                                  alt=""
                                />
                                Add Coupon Code
                              </div>
                            </Link>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="add">
                        
                        </div>
                      </div>
                      <div className="border-line mt-3"></div>
                      <div className="row mt-4">
                        <div className="  col-xxl-4 col-xl-12  col-lg-12 col-md-12 col-12">
                          <div className="row">
                            <div className="  col-lg-6 col-md-6 col-12">
                              <div className="d-flex">
                                <div className="show me-2">
                                  <p className="show mt-1">Show</p>
                                </div>
                                <div className="number me-2">
                                  <select
                                    className="form-select form-select-sm"
                                    aria-label=".form-select-sm example"
                                    onChange={(e) => setperPage(e.target.value)}
                                  >
                                    {option?.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="entries">
                                  <p className="show mt-1">entries</p>
                                </div>
                              </div>
                            </div>
                            <div className="  col-lg-6 col-md-6 col-12">
                              <div className="sowing me-2">
                                <p className="show mt-2">{`Showing ${Math.min(
                                  (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                  currentPage * perPage,
                                  data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="  col-xxl-8 col-xl-12 col-lg-12 col-md-12 col-12">
                          <div className="row">

                            <div className="col-xxl-4 col-xl-4  col-lg-4  col-md-5 col-12 mt-md-0 mt-3">
                              <div className="num me-2">
                                <Form.Control
                                  as="select"
                                  name="type"
                                  onChange={(e) => {
                                    setSearchType(e.target.value);
                                  }}
                                >
                                  <option value="">Select Type</option>
                                  <option value="percentage">Percentage</option>
                                  <option value="fixedamount">
                                    Fixed Amount
                                  </option>
                                  {/* <option value="combo">Combo</option> */}
                                </Form.Control>
                              </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4  col-lg-4  col-md-5 col-12 mt-md-0 mt-3">
                              <div className="num me-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id=""
                                  placeholder="Name / Code"
                                  value={search}
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mt-md-0 mt-3">
                              <div className="Search-1">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSearch("");
                                    setSearchType("");
                                    setReset(!reset);
                                  }}
                                  className="btn btn-reset"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                            {/* <div className="Search me-2">
                              <Button
                                type="button"
                                onClick={getDataAll}
                                className="btn btn-search"
                              >
                                <img src={search1} className="search" alt="" />
                              </Button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-3">
                      <div className="data table-responsive">
                        <Table striped bordered hover responsive center>
                          <thead>
                            <tr className="">
                              {visible.col1 && (
                                <th className="sr" style={{ width: "100px" }}>
                                  Sr. No.
                                </th>
                              )}
                              {visible.col2 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "200px" }}
                                >
                                  Type
                                </th>
                              )}
                              {visible.col3 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "200px" }}
                                >
                                  Name
                                </th>
                              )}
                              {visible.col4 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "200px" }}
                                >
                                  Code
                                </th>
                              )}
                              {visible.col5 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "200px" }}
                                >
                                  Discount
                                </th>
                              )}
                              {visible.col6 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "200px" }}
                                >
                                  Total Amount
                                </th>
                              )}
                              {/* {visible.col6 && (
                            <th className="tax-name">Password</th>
                          )} */}
                              {visible.col7 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "200px" }}
                                >
                                  Status
                                </th>
                              )}
                              {visible.col8 && (
                                <th
                                  className="tax-name"
                                  style={{ width: "200px" }}
                                >
                                  Action
                                </th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {isAllow.includes(5) ? ( */}
                            {data?.data?.data?.length > 0 ? (
                              data.data.data.map((d, index) => {
                                const paginatedIndex =
                                  (onPageChange - 1) * perPage + index + 1;
                                return (
                                  <tr className="" key={index}>
                                    {visible.col1 && <td>{paginatedIndex}.</td>}{" "}
                                    {visible.col2 && <td>{d?.type}</td>}
                                    {visible.col3 && <td>{d?.name}</td>}
                                    {visible.col4 && <td>{d?.code}</td>}
                                    {visible.col5 && <td>{d?.discount}</td>}
                                    {visible.col6 && <td>{d?.total_amount}</td>}
                                    {/* {visible.col6 && <td>{d?.password}</td>} */}
                                    {/* {visible.col6 && <td>******</td>} */}
                                    {visible.col7 && (
                                      <td>
                                        {d?.id == 0 ? (
                                          <></>
                                        ) : (
                                          <div className="form-check form-switch">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              role="switch"
                                              checked={d.status}
                                              disabled={!isAllow?.includes(63)}
                                              onChange={() => {
                                                ChangeStatus(d.id);
                                              }}
                                              id={`flexSwitchCheckDefault${d?.id}`}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                            >
                                              {d.status ? "Active" : "Inactive"}
                                            </label>
                                          </div>
                                        )}
                                      </td>
                                    )}
                                    {visible.col8 && (
                                      <td>
                                        <div className="d-flex">
                                          {isAllow?.includes(63) ? (
                                            <EditButton
                                              handleShow1={handleShow1}
                                              id={d?.id}
                                            />
                                          ) : (
                                            <></>
                                          )}

                                          {isAllow?.includes(64) ? (
                                            <DeletButton
                                              showDeleteRecord={
                                                showDeleteRecord
                                              }
                                              id={d?.id}
                                              name={d?.name}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan="10" className="text-center">
                                  Data not available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>{" "}
                        <Pagination_Holder
                          onPageChange={currentPage}
                          totalPages={totalPages}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
