import React, { useContext, useState } from "react";
import "./WithdrawRequestModal.css";
import { Form, InputGroup, Modal, Table } from "react-bootstrap";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { CancelButton } from "../../Button";
import Successfull_Modal from "../../Successfull_Modal/Successfull_Modal";
const WithdrawRequestModal = (props) => {
  const { userdata, postData, isTokenValid } = useContext(Context);
  const [walletError, setWalletError] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const [successModal, setSuccessModal] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await postData(`/seller-wallet-history/request`, data);
    if (res?.success) {
      setSuccessModal(true);
      setWalletError("");
      await isTokenValid();
      props.getDataAll();
      setTimeout(() => {
        setSuccessModal(false);
        handleClose(true);
        props.onHide();
        reset();
      }, 3000);
    } else {
      setWalletError(res?.message);
      console.error("Error fetching  data:", res?.error);
    }
  };

  return (
    <section className="WithdrawRequestModal modal-holder">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="WithdrawRequestModal modal-holder"
      >
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Request</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="withdraw_reuqestmodal">
            <div className="form_holder">
              <div className="col-md-6">
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Amount</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        className={classNames("", {
                          "is-invalid": errors?.amount,
                        })}
                        {...register("amount", {
                          required: "Amount is required",
                          validate: (value) => {
                            // Check if the value is a number and an integer
                            if (!Number.isInteger(Number(value))) {
                              return "Amount must be an integer.";
                            }

                            // Check if the value is non-negative
                            if (value < 0) {
                              return "Amount cannot be negative.";
                            }

                            // Check if the amount is less than or equal to wallet amount
                            if (value > userdata?.wallet_amount) {
                              return `Insufficient Wallet Balance`;
                            }

                            // If all conditions are satisfied, return true
                            return true;
                          },
                        })}
                      />
                    </InputGroup>
                    {errors.amount && (
                      <span className="text-danger">
                        {errors.amount.message}
                      </span>
                    )}
                    <span className="text-danger">{walletError}</span>
                  </Form.Group>
                </div>

                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Ifsc Code</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="ifsc_code"
                        placeholder="IFSC Code"
                        className={classNames("", {
                          "is-invalid": errors?.ifsc_code,
                        })}
                        {...register("ifsc_code", {
                          required: "IFSC Code is required",
                        })}
                      />
                    </InputGroup>
                    {errors.ifsc_code && (
                      <span className="text-danger">
                        {errors.ifsc_code.message}
                      </span>
                    )}
                  </Form.Group>
                </div>

                <div className="main-form-section mt-3">
                  <Form.Group>
                    <Form.Label>Account Number</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="ac_no"
                        placeholder="Account Number"
                        className={classNames("", {
                          "is-invalid": errors?.ac_no,
                        })}
                        {...register("ac_no", {
                          required: "Account Number is required",
                        })}
                      />
                    </InputGroup>
                    {errors.ac_no && (
                      <span className="text-danger">
                        {errors.ac_no.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>

              <div className="btn_holder">
                <div className="cancel_btn_holder">
                  <CancelButton name="Cancel" />
                </div>

                <div className="save_btn_holder">
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Successfull_Modal
        show={successModal}
        onHide={() => setSuccessModal(false)}
        message={"Wallet Request Sent Successfully"}
      />
    </section>
  );
};

export default WithdrawRequestModal;
