import React, { useContext, useEffect, useState } from "react";
import Header from "../Header/Header";
import { Context } from "../../utils/context";
import { Table } from "react-bootstrap";
import Pagination_Holder from "../common/Pagination_Holder/Pagination_Holder";
import "./WalletTransaction.css";
import WithdrawRequestModal from "../common/Common_Modal/WithdrawRequestModal/WithdrawRequestModal";
import Pagination from "react-bootstrap/Pagination";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { formatDate } from "../../utils/common";
const WalletTransaction = () => {
  const { IMG_URL, userdata, getData, Per_Page_Dropdown } = useContext(Context);

  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(0);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchStore, setSearchStore] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [shortCommission, setShortCommission] = useState("");
  const [changeStatus, setChangeStatus] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [onPageChange, setonPageChange] = useState(1);
  const [searchDate, setSearchDate] = useState("");
  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const getDataAll = async () => {
    const response = await getData(
      `/seller-wallet-history?page=${onPageChange}&per_page=${perPage}&term=${search}&date=${
        searchDate ? formatDate(searchDate) : ""
      }`
    );

    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    settotalPages(response?.data?.totalPages);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    search,
    searchStore,
    searchEmail,
    shortCommission,
    onPageChange,
    searchDate,
  ]);

  return (
    <section className="WalletTransaction">
      <section className="AdvanceDashboard">
        <div className="AdvanceDashboardTabs">
          <Header title={"Wallet History"} link={"/masters/social_links"} />
        </div>
      </section>

      <div className="main_table_holder MainRowsec ">
        <div className="wallet_history_div tab-radio">
          <div className="row">
            <div className="d-flex">
              <div className="add me-3">
                {/* {isAllow.includes(6) ? ( */}
                {/* <Link
                          // to="/employee/employee_details/add"
                          type="button"
                          className="btn btn-add pe-3"
                        >
                          <div onClick={() => handleShow()}>
                            <img src={plus} className="plus me-2 ms-0" alt="" />
                            Add Product
                          </div>
                        </Link> */}
                {/* ) : (
                        <></>
                      )} */}
              </div>

              <div className="add"></div>
            </div>
            <div className="row mt-4">
              <div className="col-xxl-12 col-xl-12  col-lg-12  col-md-12 col-12">
                <div className="row">
                  <div className="col-xl-3   col-lg-4  col-md-4 col-sm-2 col-2 mt-xl-0 mt-3 mb-3">
                    <div className="num me-2">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date"
                        id=""
                        value={searchDate}
                        onChange={(e) => {
                          setSearchDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-2  col-lg-3  col-md-2 col-12 mt-md-2 mt-3">
                    <div className="Search-1">
                      <button
                        type="button"
                        onClick={() => {
                          setReset(!reset);
                          setSearchDate("");
                        }}
                        className="btn btn-reset"
                      >
                        Reset
                      </button>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-line mt-3"></div>

          <div className="total_div">
            <div className="amount_holder">
              <p className="total_amount">
                <b>Total : </b> ₹ {userdata?.users_wallet?.amount}
              </p>
            </div>
            <div className="withdraw button">
              <button
                className="walletBtn"
                onClick={() => {
                  setOpenWithdrawModal(true);
                }}
              >
                Withdraw
              </button>
            </div>
          </div>

          <div className="data table-responsive">
            <Table striped bordered hover responsive center>
              <thead>
                <tr>
                  <th>Sr.No</th>
                  <th>Type</th>
                  <th>Remark</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {/* {isAllow.includes(5) ? ( */}
                {data?.data?.data?.length > 0 ? (
                  data.data.data.map((d, index) => {
                    const paginatedIndex =
                      (onPageChange - 1) * perPage + index + 1;
                    return (
                      <tr className="" key={index}>
                        {<td>{paginatedIndex}.</td>}
                        {<td> {d?.type}</td>}
                        {<td> {d?.remark}</td>}
                        {<td>₹ {d?.amount}</td>}
                        {
                          <td>
                            {" "}
                            {new Date(d?.createdAt).toLocaleDateString()}
                          </td>
                        }
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      Data not available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <Pagination_Holder
              onPageChange={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <WithdrawRequestModal
        getDataAll={getDataAll}
        show={openWithdrawModal}
        onHide={() => setOpenWithdrawModal(false)}
      />
    </section>
  );
};

export default WalletTransaction;
