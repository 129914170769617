import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Context } from "../../../utils/context";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import {
  faCartShopping,
  faBoxOpen,
  faUsers,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { Chart } from "react-google-charts";
import DataTable from "datatables.net";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";

import ProductPieChart from "./Product_pie_chart/Product_pie_chart";
import AllProductColumnChart from "./AllProduct-PieChart/Product_pie_chart";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

library.add(fas);

const AdvanceDashboard = () => {
  const {
    getData,
    usertype,
    SelectReportAnalysisData,
    Select2Data,
    SelectUsers,
    userdata,
  } = useContext(Context);

  console.log(userdata, "userdata");

  const [result, setResult] = useState({});
  const getDataAll = async () => {
    const result = await getData("/dashboard");
    setResult(result.data);
  };

  useEffect(() => {
    // getDataAll();
  }, []);

  ///////////////////////////////////////////Graph/////////////////////////////////////

  const [product, setProduct] = useState([]);
  const [productForGraph, setProductForGraph] = useState([]);
  const [productForGraphBySeller, setProductForGraphBySeller] = useState([]);
  const [productCount, setProductCount] = useState([]);
  const [productGraphCount, setProductGraphCount] = useState([]);
  const [productGraphCountBySeller, setProductGraphCountBySeller] = useState(
    []
  );
  const [sellerReset, setSellerReset] = useState();

  const [sellerData, setSellerData] = useState([]);
  const [sellerGraphData, setSellerGraphData] = useState([]);

  const [sellerCount, setSellerCount] = useState([]);
  const [sellerGraphCount, setSellerGraphCount] = useState([]);

  const [categories, setCategories] = useState([]);
  const [reportAnalysis, setReportAnalysis] = useState([]);
  const [allSellers, setAllSellers] = useState([]);

  const [productData, setProductData] = useState("");
  const [sellers, setSellers] = useState("");
  const GetAllCategory = async () => {
    const response = await getData("/allcategories");
    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllReportAnalysis = async () => {
    const response = await getData("/all-report-analysis");
    if (response?.success) {
      const data = await SelectReportAnalysisData(response?.data);
      setReportAnalysis(data);
    }
  };

  const GetAllOnlySellers = async () => {
    const response = await getData("/all-sellers");
    if (response?.success) {
      const data = await SelectUsers(response?.data);
      setAllSellers(data);
    }
  };

  const GetProductSale = async (days = 30) => {
    try {
      let url = `/most-selling-product`;

      if (days) {
        url += `?daysAgo=${days}`;
      }

      const res = await getData(url);
      setProductData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const [productGraph, setProductGraph] = useState("");
  const [productGraphBySeller, setProductGraphBySeller] = useState("");
  const [sellerGraph, setSellerGraph] = useState("");

  const GetAllProductGraph = async (days) => {
    try {
      const res = await getData(`/product-graph`);
      setProductGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetAllProductGraphBySeller = async (id, daysAgo) => {
    try {
      const res = await getData(
        `/product-graph-by-seller/${id}?daysAgo=${daysAgo}`
      );
      setProductGraphBySeller(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    GetAllProductGraphBySeller();
  }, [sellerReset]);

  useEffect(() => {
    const extractedProduct = productData?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProduct(extractedProduct);

    const extractedProductCount = productData?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductCount(extractedProductCount);
  }, [productData]);

  useEffect(() => {
    const extractedProduct = productGraph?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProductForGraph(extractedProduct);

    const extractedProductCount = productGraph?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductGraphCount(extractedProductCount);
  }, [productGraph]);

  // ......................................................All Seller Product graph......................................................
  useEffect(() => {
    const extractedProductBySeller = productGraphBySeller?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProductForGraphBySeller(extractedProductBySeller);

    const extractedProductCountBySeller = productGraphBySeller?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductGraphCountBySeller(extractedProductCountBySeller);
  }, [productGraphBySeller]);
  // ......................................................All Seller Product graph End......................................................
  useEffect(() => {
    const extractedSeller = sellers?.top5
      ?.map((value) => value.seller?.first_name)
      .filter((seller) => seller !== undefined);
    setSellerData(extractedSeller);

    const extractedSellerCount = sellers?.top5
      ?.map((value) => value.productCount)
      .filter((count) => count !== undefined);
    setSellerCount(extractedSellerCount);
  }, [sellers]);

  useEffect(() => {
    const extractedSeller = sellerGraph?.top5
      ?.map((value) => value.seller?.first_name)
      .filter((seller) => seller !== undefined);
    setSellerGraphData(extractedSeller);

    const extractedSellerCount = sellerGraph?.top5
      ?.map((value) => value.productCount)
      .filter((count) => count !== undefined);
    setSellerGraphCount(extractedSellerCount);
  }, [sellerGraph]);

  const GetSellers = async (days = 30) => {
    try {
      let url = `/most-popular-seller`;

      if (days) {
        url += `?daysAgo=${days}`;
      }
      const res = await getData(url);
      setSellers(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetSellerGraph = async (days) => {
    try {
      // let url = `/seller-graph`;

      // if (days) {
      //   url += `?daysAgo=${days}`;
      // }
      const res = await getData(`/seller-graph`);
      setSellerGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    GetAllCategory();
    GetAllReportAnalysis();
    GetAllOnlySellers();
    GetProductSale();
    GetSellers();
    GetAllProductGraph();
    GetSellerGraph();
  }, []);

  ///////////////////////////////////////////Graph Ends/////////////////////////////////////

  // search functionality start

  const [countOrders, setCountOrders] = useState("");
  const GetEditData = async () => {
    const res = await getData(`/count-all-orders`);
    setCountOrders(res?.data?.count);
  };
  const [countProducts, setCountProducts] = useState("");
  const GetProductData = async () => {
    const res = await getData(`/count-all-products`);
    setCountProducts(res?.data?.count);
  };
  const [countCustomers, setCountCustomers] = useState("");
  const GetCustomersData = async () => {
    const res = await getData(`/count-all-customers`);
    setCountCustomers(res?.data?.count);
  };
  const [countSellers, setCountSellers] = useState("");
  const GetSellersData = async () => {
    const res = await getData(`/count-all-sellers`);
    setCountSellers(res?.data?.count);
  };
  useEffect(() => {
    GetEditData();
    GetProductData();
    GetCustomersData();
    GetSellersData();
  }, []);

  const navigate = useNavigate();
  const cardsData = [
    {
      number: countOrders,
      types: "Orders",
      icon: "fa-cart-shopping",
      className: "#63bff5",
      onclick: "/order-managements/orders",
    },
    {
      number: countProducts,
      types: "Products",
      icon: "fa-solid fa-boxes-stacked",
      className: "#63bff5",
      onclick: "/product",
    },
    {
      number: countCustomers,
      types: "Customers",
      icon: "fa-solid fa-users",
      className: "#63bff5",
      onclick: "/employee/customer-details",
    },
    {
      number: countSellers,
      types: "Sellers",
      icon: "fa-solid fa-users",
      className: "#63bff5",
      onclick: "/seller-detail",
    },
    {
      number: `₹ ${userdata?.users_wallet?.amount}`,
      types: "Wallet Amount",
      icon: "fa-solid fa-users",
      className: "#63bff5",
      onclick: "/wallet/wallet-history",
    },
  ];

  // pie chart

  // close button

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  useEffect(() => {
    const table = new DataTable("#exampleContent");
    return () => {
      table.destroy();
    };
  }, []);

  const colors = ["#63bff5", "#f39c12", "#dd4b39", "#00a65a"];

  return (
    <div className="main-advancedashboard">
      <section className="AdvanceDashboard">
        <div className="AdvanceDashboardTabs">
          <Header title={"Dashboard"} link={"/masters/social_links"} />
          <div className=""></div>
        </div>
      </section>
      <div className="Main-Section-Advanced-Dashboard">
        <h1 className="title">Home</h1>
        <Row>
          {cardsData.map((item, index) =>
            usertype === "Admin" ? (
              <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                <Card
                  className="small-box"
                  style={{ backgroundColor: colors[index % colors.length] }}
                >
                  <div className={item.className}>
                    <div className="content">
                      <div class="inner">
                        <h3>{item.number}</h3>
                        <p>{item.types}</p>
                      </div>
                      <div class="icon">
                        <FontAwesomeIcon icon={item.icon} />
                      </div>
                    </div>

                    <Link to={item?.onclick}>
                      <p className="mb-0 small-box-footer">
                        More info{" "}
                        <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>
            ) : (
              index < 2 && (
                <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                  <Card
                    className="small-box"
                    style={{ backgroundColor: colors[index % colors.length] }}
                  >
                    <div className={item.className}>
                      <div className="content">
                        <div class="inner">
                          <h3>{item.number}</h3>
                          <p>{item.types}</p>
                        </div>
                        <div class="icon">
                          <FontAwesomeIcon icon={item.icon} />
                        </div>
                      </div>

                      <Link to={item?.onclick}>
                        <p className="mb-0 small-box-footer">
                          More info{" "}
                          <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                        </p>
                      </Link>
                    </div>
                  </Card>
                </Col>
              )
            )
          )}

          {usertype == "Seller" ? (
            <>
              <div className="col-xl-12 col-lg-6 col-md-12 col-12 mt-5">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="chart-heading">
                      <h1> Products</h1>
                    </div>
                    {productForGraph?.length > 0 &&
                      productGraphCount?.length > 0 && (
                        <ProductPieChart
                          productData={productForGraph}
                          productCount={productGraphCount}
                        />
                      )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className=" col-12 mt-5">
                <div className="chart-heading">
                  <h1>All Products Graph</h1>
                </div>
                {productForGraph?.length > 0 &&
                  productGraphCount?.length > 0 && (
                    <AllProductColumnChart
                      productData={productForGraph}
                      productCount={productGraphCount}
                    />
                  )}
              </div>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default AdvanceDashboard;
